import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Button, TextField } from '@mui/material';
import { grey, yellow, blue, red } from '@mui/material/colors'
import { CheckCircle, DoNotDisturbOn, Cancel, AccessTime, Error, Report } from '@mui/icons-material';
import { getSubmissionsData } from '../../helpers/Api/ApiHelper';
import './AuditTable.css';


function determineIcon(status) {
  const icon_map = {
    "Uploaded": <DoNotDisturbOn fontSize="medium" sx={{ color: grey[500] }} />,
    "Accepted": <CheckCircle fontSize="medium" color='success' />,
    "Duplicated": <Cancel fontSize="medium" sx={{ color: yellow[600] }} />,
    "Rejected": <Cancel fontSize="medium" sx={{ color: yellow[600] }} />,
    "Started": <DoNotDisturbOn fontSize="medium" sx={{ color: grey[500] }} />,
    "Being Held": <AccessTime fontSize="medium" sx={{ color: blue[500] }} />,
    "Processing": <DoNotDisturbOn fontSize="medium" sx={{ color: grey[500] }} />,
    "Cancelled": <Cancel fontSize="medium" sx={{ color: yellow[600] }} />,
    "Failed": <Error fontSize="medium" sx={{ color: red[700] }} />,
    "Invalid Data": <Error fontSize="medium" sx={{ color: red[700] }} />,
    "Completed": <CheckCircle fontSize="medium" color='success' />,
    "Partially Completed": <Report fontSize="medium" sx={{ color: yellow[600] }} />
  }
  return icon_map[status]
}

export default function AuditTable(props) {
  const fetch_delay = 1500 // 1.5s delay on search before sending api call
  const [apiData, setApiData] = useState([]);
  const [apiPage, setApiPage] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState("");
  const [refreshDisabled, setRefreshDisabled] = useState(false);

  const refreshData = async () => {
    const newData = getSubmissionsData(props.auth, filter, 1)
    setRefreshDisabled(true)
    setApiData(await newData)
    setPage(0);
    setApiPage(1);
    setRefreshDisabled(false)
  };

  React.useEffect(() => {
    const getData = async (filter) => {
      if(props.auth?.isAuthenticated) {
        let newData = getSubmissionsData(props.auth, filter, 1)
        setRefreshDisabled(true)
        setApiData(await newData)
        setPage(0);
        setApiPage(1);
        setRefreshDisabled(false)
      }
    }

    let timer = setTimeout(() => {
      getData(filter)
    }, fetch_delay)
    
    return () => clearTimeout(timer)

  }, [props.auth, filter]);

  const fetchNextPage = async () => {
    console.log(apiPage+1)
    const newData = await getSubmissionsData(props.auth, filter, apiPage+1)
    setApiPage(apiPage+1)
    setApiData([...apiData, ...newData])
  };

  const handleChangePage = (event, newPage) => {
    if (((newPage*rowsPerPage)+rowsPerPage) >= apiData.length) {
      fetchNextPage()
    }
    setPage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const hashCode = (s) => {
    return s.split("").reduce(function(a, b) {
      a = ((a << 5) - a) + b.charCodeAt(0);
      return a & a;
    }, 0);
  };
  
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const defaultDisplay = () => {
    if (props.auth?.isAuthenticated) {
      return <div className='audit-section-container'>
        <div className='audit-table-title'> Previous Submissions </div>
        <div className='audit-table-utility-rows'>
          <TextField
            className="audit-table-searchbar"
            data-testid="audit-filter"
            label="Filter File Name..."
            variant="outlined"
            size="small"
            value={filter}
            onChange={handleFilterChange}/>
          <Button 
            className='audit-table-refresh-button'
            variant="outlined"
            data-testid="refresh-button"
            onClick={() => {refreshData()}}
            disabled={refreshDisabled}
          > 
            Refresh Status Table
          </Button>
        </div>
        <div className='audit-table-container'>
          <div className='audit-table'>
            <TableContainer component={Paper}>
              <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow key="audit-table-header-row" className='audit-table-header'>
                    <TableCell className='audit-table-header-cell'>File Name</TableCell>
                    <TableCell className='audit-table-header-cell' align="left">File Type</TableCell>
                    <TableCell className='audit-table-header-cell' align="left">Uploaded&nbsp;(ET)</TableCell>
                    <TableCell className='audit-table-header-cell' align="left">Group ID</TableCell>
                    <TableCell className='audit-table-header-cell' align="left">Username</TableCell>
                    <TableCell className='audit-table-header-cell' align="left">Validation Status</TableCell>
                    <TableCell className='audit-table-header-cell' align="left">Processing Status</TableCell>
                    <TableCell className='audit-table-header-cell' align="left">Last Updated&nbsp;(ET)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apiData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow key={hashCode(row.file_nm+row.validation_status_ts)}>
                      <TableCell className='audit-table-data-cell' component="th" scope="row">
                        {row.file_nm}
                      </TableCell>
                      <TableCell className='audit-table-data-cell' align="left">{row.published_file_ind === "N" ? "Data File" : "Published Report"}</TableCell>
                      <TableCell className='audit-table-data-cell' align="left">{
                        new Date(row.validation_status_ts).toLocaleString('en-US', {timeZone: 'America/New_York', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                      </TableCell>
                      <TableCell className='audit-table-data-cell' align="left">{row.group_id}</TableCell>
                      <TableCell className='audit-table-data-cell' align="left">{row.user_nm}</TableCell>
                      <TableCell className='audit-table-data-cell' align="left"><div className="validation-status-container">{determineIcon(row.validation_status)} {row.validation_status}</div></TableCell>
                      <TableCell className='audit-table-data-cell' align="left"><div className="processing-status-container">{determineIcon(row.processing_status)} {row.processing_status}</div></TableCell>
                      <TableCell className='audit-table-data-cell' align="left">{
                        new Date(row.processing_status_ts).toLocaleString('en-US', {timeZone: 'America/New_York', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})
                      }</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={apiData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    }
  }

  return (
    defaultDisplay()
  );
}