import * as React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './Header.css';

export default function Header(props) {
    const signoutRedirectArgs = {
        post_logout_redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/signout`,
        extraQueryParams: {
            TargetResource: `${process.env.REACT_APP_REDIRECT_URI}/signout`
        },
        redirectTarget: "top",
        redirectMethod: "replace"
    }

    function auth_button(auth) {
        auth?.isAuthenticated? signout(auth) : signin(auth)
    }

    function signout(auth) {
        auth.signoutRedirect(signoutRedirectArgs)
    }

    function signin(auth) {
        auth.signinRedirect()
    }

    if (props.auth?.isAuthenticated || window.location.pathname === "/signout") {
        return (
            <AppBar position="static">
                <Toolbar sx={{
                    backgroundColor: "#0071BC",
                    color: "white",
                    justifyContent: "space-between",
                    minHeight: "64px"
                }}>
                    <div className='App-nav-title'>
                        <Link to="/">
                            Customer Drop Zone
                        </Link>
                    </div>
                    <div className='App-nav-auth'>
                        <div className='App-nav-auth-user'>
                            {props.auth?.isAuthenticated? `${props.auth.user.profile.given_name} ${props.auth.user.profile.family_name}` : ""}
                        </div> 
                        <div className='App-nav-auth-divider'>
                            {props.auth?.isAuthenticated? "|" : ""}
                        </div>
                        <Button 
                            className="App-nav-auth-button" 
                            data-testid="testAuthButton" 
                            color="inherit" 
                            variant="text" 
                            size="large" 
                            onClick={() => {auth_button(props.auth)}}
                        >
                            {props.auth?.isAuthenticated? "Log Out" : "Log In"}
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}