import './PrivacyPolicy.css';
import React from 'react';
import AuthPageWrapper from '../../helpers/Auth/AuthPageWrapper';
import AuthComponentWrapper from '../../helpers/Auth/AuthComponentWrapper';
import Header from '../../components/Header/Header';
import WarningDialog from '../../components/WarningDialog/WarningDialog';
import Footer from "../../components/Footer/Footer";

function ContactUs() {
  if (process.env.REACT_APP_AUTH_ENABLED === "true") {
    return (
      <AuthPageWrapper childName="main-page">
        <div className="PrivacyPolicy">
          <div className='Main-header'>
            <AuthComponentWrapper childName="main-page-header">
              <WarningDialog></WarningDialog>
              <Header></Header>
              <h1 className='page-name'>Privacy Policy</h1>
              <div className='privacy-policy-text'>
                If you visit the <a href='/' className='home-link'>Customer Drop Zone</a> website to read or download
                information, we collect and store only the following type of information about you: the name of the
                domain from which you access the Internet; the date and time you access our site; and the Internet
                address of the website from which you linked directly to our site. We will not obtain personally
                identifiable information about you when you visit our site unless you voluntarily choose to provide
                such information to us by e-mail, by completing a comment form, or other on-line form. The U.S.
                Department of the Treasury (Treasury) does not give, sell or transfer personal information to third
                parties unless required by law, such as the Freedom of Information Act.<br />
                <br />
                For site management, information is collected for statistical purposes. Computer software programs are
                used to create summary statistics about visits to
                the <a href='/' className='home-link'>Customer Drop Zone</a> website, which are used for
                such purposes as assessing what information is of most and least interest, determining technical
                design specifications, and identifying system performance or problem areas. In order to gather
                statistical information and to administer a customer satisfaction survey,
                the <a href='/' className='home-link'>Customer Drop Zone</a> website utilizes session
                cookies which are used only for the duration of a session - the cookie is automatically
                erased when a user closes the browser. The temporary cookie contains a system-generated
                session id only. No information subject to the Privacy Act of 1974, 5 U.S.C. § 552a, such
                as personally identifiable information like a name or address, is collected or used for this
                analysis. Raw data logs are used for no other purposes and are scheduled for regular destruction
                in accordance with National Archives and Records Administration Guidance.<br />
                <br />
                Except for authorized law enforcement investigations, no other attempts are made to identify
                individual users or their usage habits. Individuals are strongly discouraged from sending
                any personal information, like a social security number, to
                the <a href='/' className='home-link'>Customer Drop Zone</a> website
                through email, for example, to KCFSDatasetAlerting@kc.frb.org.<br />
                <br />
                When you contact us electronically, we only share your personal information with authorized
                individuals within our organization and contractors who support us. For example, we may refer
                your question to the program office qualified to provide the most complete answer. For more
                information on the <a href="https://home.treasury.gov/footer/privacy-act" target="_blank" rel="noreferrer"
                                      className='data-link'>Privacy Act</a>, please visit Treasury's Privacy Act page.<br />
                <br />
                <br />
                <br />
                <b>Notice of Monitoring</b><br />
                <br />
                You are entering an Official United States Government System which may be used only for authorized
                purposes. The Government may monitor and audit usage of this system, and all persons are hereby
                notified that use of this system constitutes consent to such monitoring and auditing.<br />
                <br />
                Unauthorized attempts to upload information and/or change information on these websites are strictly
                prohibited and are subject to prosecution under the Computer Fraud and Abuse Act of 1986
                and 18 U.S.C. §§1001 and 1030.<br />
                <br />
                <b>Disclaimer of Endorsement</b><br />
                <br />
                Treasury does not endorse any commercial product, service, process, or enterprise. Links to other
                websites and references to any commercial product or enterprise are provided solely for the
                convenience of the user and do not constitute an endorsement or recommendation. Treasury
                assumes no responsibility for the content or operation of other websites.<br />
                <br />
                <b>Disclaimer of Liability</b><br />
                <br />
                The United States Government (including the Department of the Treasury) makes no warranty,
                express or implied, including the warranties of merchantability and fitness for a particular
                purpose and assumes no legal liability or responsibility for the accuracy, completeness,
                or usefulness of any information, apparatus, product, or process described or depicted on
                this website and does not represent that its use would not infringe privately owned rights.<br />
                <br />
                <b>External Links and Social Media</b><br />
                <br />
                This website includes links to government and non-government external sites. Non-government
                websites do not necessarily operate under the same laws, regulations, and policies as federal
                websites. If you click a link to an outside non-government website, you will leave
                the <a href='/' className='home-link'>Customer Drop Zone</a> website and are subject to the privacy
                and security policies of the owners/sponsors of the outside non-government website.<br />
                <br />
                a. <b>Treasury's Purpose in Using Social Media</b>-Treasury uses 3rd party social media sites
                such as Facebook, Twitter, and YouTube as additional means of reaching the public and directing
                people to <a href="https://home.treasury.gov/" target="_blank" rel="noreferrer"
                             className='data-link'>www.Treasury.gov</a>, which is the official website of the U.S.
                Department of the Treasury.<br />
                <br />
                b. <b>Treasury does not use, maintain, or share personally identifiable information (PII)
                made available through social media/3rd party sites</b>. You can access information published to
                these sites from the top navigation icons or Treasury's Connect with Us page without having an
                account with or subscribing to these 3rd party services. If you access one of these sites
                without an account, no personally identifiable information is exposed to Treasury. If you
                subscribe to one of these services and your account name, on-line profile, or comments you
                make happens to reveal personally identifiable information, we will not use, maintain, or
                share that information outside the agency.<br />
                <br />
                c. <b>Please Review the Privacy Policies of these Social Media/3rd Party Sites</b>. You should
                familiarize yourself with the privacy policies of any social media site or 3rd party site you
                use. Some of these sites may use persistent cookies in order to improve the service they provide
                to their users. <a href="https://www.usa.gov/optout-instructions" target="_blank" rel="noreferrer"
                                   className='data-link'> Instructions to opt out of receiving cookies are available
                at USA.gov</a>, the official portal of the U.S. Government. If you actually subscribe to these services,
                you may be revealing personally identifiable information to a 3rd party, non-government entity.
                Since Treasury's social media content is available to the general public, if you make a
                comment on a social media site, any information in your comment, as well as your profile
                name and any other public profile information, will be available to the general public to see.<br />
                <br />
                <b>Copyright Status</b><br />
                <br />
                No copyright may be claimed for any work on this website that was created or maintained by a
                federal employee in the course of their duties. Images and text appearing on this website may
                be freely copied. Credit is requested. If copyrighted material appears on the site, or is reached
                through a link on this site, the copyright holder must be consulted before the material may
                be reproduced.<br />
                <br />
                <b>Official Seal, Names and Symbols</b><br />
                <br />
                Federal law prohibits use of any symbol, emblem, seal, insignia or badge of any entity of the
                Department of the Treasury or any colorable imitation of such words, initials, symbols,
                emblems, or seals in connection with any advertisement, solicitation, business activity,
                or product where such use could reasonably be interpreted as conveying the false impression
                that such advertisement, solicitation, business activity, or product is in any manner approved,
                endorsed, sponsored, or authorized by, or associated with, the Department of the Treasury or
                any organization within the Department. See 18 U.S.C. § 701, 31 U.S.C. § 333
                and 31 C.F.R. Part 37.<br />
              </div>
            </AuthComponentWrapper>
          </div>
        </div>
        <Footer></Footer>
      </AuthPageWrapper>
    );
  } else {
    return (
      <div className="Main">
        <div className='Main-header'>
          <Header auth={null}></Header>
        </div>
      </div>
    )
  }
  
}

export default ContactUs;
