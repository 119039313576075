import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Main from './pages/Main/Main';
import Signout from './pages/Signout/Signout';
import { AuthProvider } from "react-oidc-context";
import { User } from 'oidc-client-ts';
import ContactUs from "./pages/ContactUs/ContactUs";
import Accessibility from "./pages/Accessibilty/Accessibility";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState(
      {},
      document.title,
      window.location.pathname
  )
  window.sessionStorage.setItem("monitorAgreed", "false");
}

const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  response_type: 'code',
  loadUserInfo: true
}

export default function App() {
  if (process.env.REACT_APP_AUTH_ENABLED === 'true') {
    return (
      <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/signout" element={<Signout />}></Route>
            <Route path="/ContactUs" element={<ContactUs />}></Route>
            <Route path="/Accessibility" element={<Accessibility />}></Route>
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    );
  }
  else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
