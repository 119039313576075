import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './WarningDialog.css';

export default function WarningDialog(props) {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.auth?.isAuthenticated || process.env.REACT_APP_AUTH_ENABLED === "false") {
            if (window.sessionStorage.getItem('monitorAgreed') === "false" ) {
                handleClickOpen()
            }
        }
    }, [props.auth?.isAuthenticated])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        window.sessionStorage.setItem("monitorAgreed", "true");
        setOpen(false);
    };

    return (
        <div>
        <Dialog
            className='alert-dialog-container'
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="alert-dialog-title">
                {"Warning Warning Warning"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="alert-dialog-description">
                You have accessed a U.S. Government information system, which includes (1) this computer, (2) this network, (3) all computers connected to this network, and (4) all devices and storage media attached to this network or to a computer on this network. U.S. Government information systems are provided for the processing of official U.S. Government information only. Unauthorized or improper use of this information system is prohibited and may subject you to disciplinary action, as well as civil and criminal penalties. All data contained on U.S. Government information systems is owned by the U.S. Government and may, for the purpose of protecting the rights and property of the U.S. Government, be monitored, intercepted, recorded, read, searched, copied, or captured in any manner and disclosed or used for any lawful government purpose at any time. THERE IS NO RIGHT TO PRIVACY IN THIS SYSTEM. System personnel may give to law enforcement officials any potential evidence of crime found on U.S. Government information systems. USE OF THIS SYSTEM BY ANY USER, AUTHORIZED OR UNAUTHORIZED, CONSTITUTES YOUR UNDERSTANDING AND CONSENT TO THIS MONITORING, INTERCEPTION, RECORDING, READING, COPYING, OR CAPTURING AND DISCLOSURE.
            </DialogContentText>
            </DialogContent>
            <DialogActions className="alert-dialog-actions">
            <Button className="alert-dialog-button" data-testid="testAgreeButton" onClick={handleClose} autoFocus>
                I Agree
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}