import './Main.css';
import React from 'react';
import AuthPageWrapper from '../../helpers/Auth/AuthPageWrapper';
import AuthComponentWrapper from '../../helpers/Auth/AuthComponentWrapper';
import Header from '../../components/Header/Header';
import WarningDialog from '../../components/WarningDialog/WarningDialog';
import Dropzone from '../../components/Dropzone/Dropzone';
import AuditTable from '../../components/AuditTable/AuditTable';
import { SnackbarProvider } from 'notistack';
import Footer from '../../components/Footer/Footer'

function Main() {
  if (process.env.REACT_APP_AUTH_ENABLED === "true") {
    return (
      <AuthPageWrapper childName="main-page">
        <SnackbarProvider maxSnack={4}>
          <div className="Main">
            <div className='Main-header'>
              <AuthComponentWrapper childName="main-page-header">
                <WarningDialog></WarningDialog>
                <Header></Header>
                <Dropzone></Dropzone>
                <hr className='main-page-divider' style={{
                    color: 'black',
                    backgroundColor: 'black',
                    height: 3
                }} />
                <AuditTable></AuditTable>
                <Footer></Footer>
              </AuthComponentWrapper>
            </div>
          </div>
        </SnackbarProvider>
      </AuthPageWrapper>
    );
  } else {
    return (
      <div className="Main">
        <div className='Main-header'>
          <Header auth={null}></Header>
          <Dropzone auth={null}></Dropzone>
          <Footer></Footer>
        </div>
      </div>
    )
  }
  
}

export default Main;
