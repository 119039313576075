import * as React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export default function Footer(props) {

    if (props.auth?.isAuthenticated) {
        return (
            <div className="Footer">
                <div className="footer-content">
                    <Link to="../Accessibility">
                        Accessibility
                    </Link>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link to="../PrivacyPolicy">
                        Privacy Policy Disclaimer
                    </Link>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link to="../ContactUs">
                        Contact Us
                    </Link>
                    &emsp;
                    <div className='Logo'>
                        <img alt='' src='treasuryLogoStatic.png' width="80" height="80"/>
                    </div>
                </div>
            </div>
        );
    }
}