import './Signout.css';
import React from 'react';
import Header from '../../components/Header/Header';
import AuthComponentWrapper from '../../helpers/Auth/AuthComponentWrapper';

function Signout() {
  return (
    <AuthComponentWrapper childName="main-page-header">
      <Header></Header>
      <div className="container1">
        <div className="message">
          Thank you for visiting the Customer Drop Zone. Your session has ended.<br />
          Please close your browser or log in again if you wish to continue.
        </div>
      </div>
    </AuthComponentWrapper>
  )
}

export default Signout;