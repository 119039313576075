import './Accessibility.css';
import React from 'react';
import AuthPageWrapper from '../../helpers/Auth/AuthPageWrapper';
import AuthComponentWrapper from '../../helpers/Auth/AuthComponentWrapper';
import Header from '../../components/Header/Header';
import WarningDialog from '../../components/WarningDialog/WarningDialog';
import Footer from "../../components/Footer/Footer";

function ContactUs() {
  if (process.env.REACT_APP_AUTH_ENABLED === "true") {
    return (
      <AuthPageWrapper childName="main-page">
        <div className="Accessibility">
          <div className='Main-header'>
            <AuthComponentWrapper childName="main-page-header">
              <WarningDialog></WarningDialog>
              <Header></Header>
              <h1 className='page-name'>Accessibility</h1>
              <div className='accessibility-text'>
                <b>The Rehabilitation Act</b><br />
                <br />
                <b>Web Accessibility and Section 508</b><br />
                <br />
                The U.S. Department of the Treasury (Treasury) is committed to making
                the <a href='/' className='home-link'>Customer Drop Zone</a> accessible
                to authorized users and ensuring that it meets or exceeds
                the requirements of <a href='https://www.section508.gov/manage/laws-and-policies/' target="_blank"
                                       rel="noreferrer"  className='data-link'>Section 508 of the
                Rehabilitation Act, codified
                at 29 U.S.C. § 794d</a>.<br />
                <br />
                <br />
                <br />
                If you have any section 508 issues with any page on our site, please contact the FIR
                Outreach and Support Help Desk at:<br />
                <br />
                Email address: KCFSDatasetAlerting@kc.frb.org.<br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <b>Physical Accessibility and Section 504</b><br />
                <br />
                Treasury is an Equal Opportunity Provider and Employer. Its programs and activities are
                accessible to persons with disabilities.<br />
                <br />
                <br />
                <br />
                <b>Your Rights</b><br />
                <br />
                If you are a qualified person with a disability, and experience difficulties
                accessing the <a href='/' className='home-link'>Customer Drop Zone</a> because
                of your disability, you can request
                a reasonable accommodation under Section 504 of the Rehabilitation Act, in order
                to access our website. You can make your request for an accommodation by contacting
                us at KCFSDatasetAlerting@kc.frb.org.<br />
                <br />
                <br />
                <br />
                <b>What Is Section 504?</b><br />
                <br />
                Section 504 is a federal law that protects qualified individuals from discrimination
                based on their disability. The nondiscrimination requirements of the law apply to
                organizations that receive financial assistance from any Federal department or
                agency, including the U.S. Department of the Treasury.<br />
                <br />
                Section 504 prohibits organizations and agencies from excluding or denying individuals
                with disabilities an equal opportunity to receive program benefits and services.
                It defines the rights of individuals with disabilities to participate in, and have
                access to, program benefits and services.<br />
                <br />
                <br />
                <br />
                <b>Who Is Protected from Discrimination?</b><br />
                <br />
                Section 504 protects <b>qualified individuals with disabilities</b>. Under this law, <b>individuals
                with disabilities</b> are defined as persons with a physical or mental
                impairment which substantially limits one or more major life activities. Section
                504 also covers people who have a history of, or who are regarded as having a
                physical or mental impairment that substantially limits one or more major life
                activities. Major life activities include, but are not limited to, caring for
                one's self, walking, seeing, hearing, speaking, breathing, working, performing
                manual tasks, and learning.<br />
                <br />
                In addition to meeting the above definition, for purposes of receiving services, <b>qualified
                individuals with disabilities</b> are persons who meet normal and essential
                eligibility requirements of a program.<br />
                <br />
                <br />
                <br />
                <b>Reasonable accommodation</b> means Treasury, or its recipient is required to take reasonable steps
                to accommodate your disability unless it would cause the entity an undue burden.
                A recipient of federal financial assistance may not, on the basis of disability:<br />
                <br />
                <li>Deny qualified individuals the opportunity to participate in or benefit from federally funded
                programs, services, or other benefits.</li>
                <li>Deny access to programs, services, benefits or opportunities to participate as a
                result of physical barriers.</li>
                <li>Deny employment opportunities, including hiring, promotion, training, and fringe benefits,
                for which they are otherwise entitled or qualified.</li>
                <br />
                <br />
                <b>Resources for Persons with Disabilities</b><br />
                <br />
                <b>Relay Calls</b><br />
                <br />
                You can call any Treasury Office using the Federal Relay Service.<br />
                <br />
                <br />
                <br />
                <b>Federal Relay Service (FedRelay)</b><br />
                <br />
                The Federal Relay Service (FedRelay) is a federal government telecommunications service, which
                enables federal employees who are deaf, hard-of-hearing, deaf/blind, or have speech disabilities
                equal communication access. Using FedRelay, federal employees may conduct official duties.
                The FedRelay also allows the general public to conduct business with the federal government
                and its agencies. Calls are relayed using specially trained Communications Assistants (CA).
                The CA simply acts as a transparent conduit for the transmittal of information. The FedRelay
                is accessible domestically (50 states as well as Puerto Rico, the Virgin Islands, Guam, and
                the District of Columbia) 24 hours a day, 7 days a week, 365 days a year
                (including federal holidays).<br />
                <br />
                All calls are strictly confidential, and no records of conversations are maintained.<br />
                <br />
                Toll-Free and Toll Access Numbers for Federal Relay are:<br />
                <br />
                <li>(800) 877-8339 TTY / ASCII (American Standard Code For Information Interchange)</li>
                <li>(877) 877-6280 VCO (Voice Carry Over)</li>
                <li>(877) 877-8982 Speech-to-Speech</li>
                <li>(800) 845-6136 Spanish</li>
                <li>(800) 877-0996 Customer Service (Voice/TTY, ASCII and Spanish)</li>
                <li>(866) 377-8642 Voice</li>
                <li>(866) 893-8340 TeleBraille</li>
                From non-domestic locations the number is (605) 331-4923.<br />
                <br />
                <br />
                <br />
                <b>Office of Civil Rights and Diversity - Civil Rights Program</b><br />
                <br />
                For comments about accessibility to Treasury programs, information about how to file a complaint
                of discrimination, or to obtain information of a civil rights nature, please contact the Department
                of the Treasury, Equal Employment Opportunity (EEO) Director, 1500 Pennsylvania Ave., NW,
                Washington, DC 20220, or by phone at (202) 622-1278. EEO employees will make every effort
                to provide prompt service.<br />
              </div>
            </AuthComponentWrapper>
          </div>
        </div>
        <Footer></Footer>
      </AuthPageWrapper>
    );
  } else {
    return (
      <div className="Main">
        <div className='Main-header'>
          <Header auth={null}></Header>
        </div>
      </div>
    )
  }
  
}

export default ContactUs;
