import * as React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { FormControl, Select, InputLabel, MenuItem, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { submitFile } from '../../helpers/Api/ApiHelper';
import { getBusinessGroupsWrite } from '../../helpers/BusinessGroups/BusinessGroups';

import './DZ_Uploader.css';
import './DZ_Toolbar.css';

export default function Dropzone(props) {
  const groups = getBusinessGroupsWrite(props.auth) 
  const [groupId, setGroupId] = React.useState(groups.length === 1? groups[0] : '');
  const [dzFiles, setDzFiles] = React.useState([]);
  const [dzUpdater, setDzUpdater] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const filesLimit = 4;
  const fileSizeLimit = 53687091200; // 50 Mb

  const handleChange = (event) => {
    setGroupId(event.target.value);
  };

  function submit_files(auth) {
    if (groupId === '') {
      enqueueSnackbar('Please select a Group ID.', { variant: "error"})
    }
    else {
      dzFiles.map(async (file) => {
        let response = await submitFile(auth, groupId, file)
        if (response.status_code === 200) {
          enqueueSnackbar(response.message, { variant: "success"})
          removeFile(file)
        }
        else if (response.status_code === 403 && response.message === "File Not Accepted - File not expected") {
          enqueueSnackbar("File does not match what is expected. Please check filename and try to upload again", { variant: "error"})
        }
        else if (response.status_code === 401 && response.message === "Upload Not Authorized - User not authorized to upload this file") {
          enqueueSnackbar("User not provisioned to upload this file", { variant: "error"})
        }
        else {
          enqueueSnackbar(response.message, { variant: "error"})
        }
        return true
      })
    }
  }

  const removeFile = (file) => {
      setDzFiles(dzFiles.filter((o) => {
        return o.name !== file.name}));
      updateDropzone()
  };

  const updateDropzone = () => {
    const newKey = dzUpdater + 1
    setDzUpdater(newKey)
  }

  const defaultDisplay = () => {
      if (props.auth?.isAuthenticated) {
          return <div className='dropzone-container'>
              <DropzoneArea
                  key = {dzUpdater}
                  onChange={(files) => {
                    setDzFiles(files)
                  }}
                  showFileNames={true}
                  showAlerts={false}
                  onDrop={(droppedFiles) => {droppedFiles.map((file) => {
                    enqueueSnackbar(file.name + " has been added.", { variant: "info" })
                    setDzFiles([...dzFiles,...droppedFiles])
                    return true
                  })}}
                  onAdd={(newFiles) => {newFiles.map((file) => {
                    enqueueSnackbar(file.name + " has been added.", { variant: "info" })
                    setDzFiles([...dzFiles,...newFiles])
                    return true
                  })}}
                  onDelete={(deletedFileObject) => {enqueueSnackbar(deletedFileObject.name + " has been removed.", { variant: "info" })}}
                  onDropRejected={(rejectedFiles, evt) => {
                    rejectedFiles.map((file) => {
                      enqueueSnackbar(file.name + " is not an acceptable file type.", { variant: "error" });
                      return true; 
                    })
                  }}
                  onAlert={(message, variant) => {
                    if(message.includes("Maximum allowed number of files exceeded")) {
                      enqueueSnackbar(message, { variant: variant })
                    }
                  }}
                  fileObjects={dzFiles}
                  className="dropzone-area"
                  dropzoneText="Drag and drop or click here to choose your file."
                  acceptedFiles={[".pdf", ".txt", ".csv", ".xls", ".xlsx", ".xlsm", ".xlw", ".xml", ".asc", ".zip"]}
                  maxFileSize={fileSizeLimit} // 50 Mb
                  filesLimit={filesLimit}
                  previewGridProps={{
                    container: { spacing: 0 }
                }}
              />
              <div className='App-dz-toolbar'>
                <div className='App-dz-gids-container'>
                  <FormControl fullWidth className='App-dz-gids-control' size='small'>
                    <InputLabel className="App-dz-gids-label">Group ID</InputLabel>
                    <Select
                      className='App-dz-gids'
                      value={groupId}
                      label="Group ID"
                      data-testid="group-id-selector"
                      onChange={handleChange}
                      disabled={groups.length===0?true:false}
                    >
                      {groups.map(function(group, i){
                          return <MenuItem key={i} value={group} className={"App-dz-gids-menu-option"}>{group}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className='App-dz-submit-button-container'>
                  <Button 
                    className='App-dz-submit-button'
                    variant="outlined"
                    data-testid="upload-button"
                    onClick={() => {submit_files(props.auth)}}
                    disabled={dzFiles.length === 0 ? true : false}
                  > 
                    Upload File(s)
                  </Button>
                </div>
              </div>
          </div>
      }
  }

  return (
      defaultDisplay()
  );
}