import './ContactUs.css';
import React from 'react';
import AuthPageWrapper from '../../helpers/Auth/AuthPageWrapper';
import AuthComponentWrapper from '../../helpers/Auth/AuthComponentWrapper';
import Header from '../../components/Header/Header';
import WarningDialog from '../../components/WarningDialog/WarningDialog';
import Footer from "../../components/Footer/Footer";

function ContactUs() {
  if (process.env.REACT_APP_AUTH_ENABLED === "true") {
    return (
      <AuthPageWrapper childName="main-page">
        <div className="ContactUs">
          <div className='Main-header'>
            <AuthComponentWrapper childName="main-page-header">
              <WarningDialog></WarningDialog>
              <Header></Header>
              <h1 className='page-name'>Contact Us</h1>
              <div className='contact-us-text'>The <a href='/' className='home-link'>Customer
                Drop Zone</a> will expand over time to provide authorized users with access to
                integrated information through a single website that promotes transparency, facilitates
                better decision making, and improves operational efficiency. Your participation is critical
                to the success of this effort, and we value your feedback.<br />
                <br />
                &emsp;If you encounter technical issues, have questions about the information or data on the
                site, or if you have suggestions about how to improve the site, please contact us via:<br />
                <br />
                &emsp;E-mail address: KCFSDatasetAlerting@kc.frb.org
              </div>
            </AuthComponentWrapper>
          </div>
        </div>
        <Footer></Footer>
      </AuthPageWrapper>
    );
  } else {
    return (
      <div className="Main">
        <div className='Main-header'>
          <Header auth={null}></Header>
        </div>
      </div>
    )
  }
  
}

export default ContactUs;
