import React from 'react';
import { useAuth } from 'react-oidc-context';

export default function AuthComponentWrapper(props) {
    const auth = useAuth();

    const childrenWithProps = React.Children.map(props.children, child => {
        // Checking isValidElement is the safe way and avoids a
        // typescript error too.
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { auth });
        }
        return child;
      });
    

    return (
        <div className={'App-auth-'+props.childName}>
            {childrenWithProps}
        </div>
    )
}

