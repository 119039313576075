import React from 'react';
import { useAuth, hasAuthParams } from 'react-oidc-context';

export default function AuthPageWrapper(props) {
    const auth = useAuth();

    React.useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
        auth.signinSilent();
    })
    }, [auth, auth.events, auth.signinSilent]);

    return (
        <div className={'App-auth-'+props.childName}>
            {props.children}
        </div>
    )
}

