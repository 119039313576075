export function submitFile (auth, group_id, file) {

  const formData = new FormData();
  formData.append("file", file);
  formData.append("group_id", group_id);

  const res = fetch(
    process.env.REACT_APP_API_BASE_URL + '/upload',
    {
      method: 'POST',
      body: formData,
      headers: {
        "Authorization": "Bearer " + auth.user?.id_token + ", Bearer " + auth.user?.access_token,
        "Access-Control-Allow-Origin": "*"
      }
    }
  ).then((response) => {
    return response.json()
  }).then((data) => {
    return data
  }).catch((err) => {
    console.log(err)
    return {
      status_code: 500,
      message: "Failed to upload file - " + file.name
    }
  })

  return res
}

export function getSubmissionsData (auth, filter, page=1) {

  const res = fetch(
    process.env.REACT_APP_API_BASE_URL + `/submissions?page=${page}${filter==="" ? "" : `&filter_param=${filter}`}`,
    {
      method: 'Get',
      headers: {
        "Authorization": "Bearer " + auth.user?.id_token + ", Bearer " + auth.user?.access_token,
        "Access-Control-Allow-Origin": "*"
      }
    }
  )
  .then((response) => {
    return response.json()
  })
  .then((data) => {
    return data['submissions']
  }).catch((err) => {
    console.log(err)
    return [] // Return empty list
  })

  return res
}