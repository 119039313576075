const roleMap = {
    dz_acdmb: "ACDMB",
    dz_arad: "ARAD",
    dz_cab: "CAB",
    dz_cfrb: "CFRB",
    dz_crb:"CRB",
    dz_fibb: "FIBB",
    dz_fiscaldata: "FISCALDATA",
    dz_fmb: "FMB",
    dz_fmbafmt: "FMBAFMT",
    dz_jfb: "JFB",
    dz_mts: "MTS",
    dz_sdab: "SDAB",
    dz_sib: "SIB",
    dz_tab: "TAB"
}

export function getBusinessGroupsWrite(auth) {
    if(auth?.isAuthenticated) {
        const roles = auth.user.profile['role'].replace("[", "").replace("]", "").split(', ')
        
        let busGroups = []

        roles.map((role) => {
            if (role === "admin") {
                busGroups = []
                Object.entries(roleMap).map((key, value) => {
                    busGroups.push(roleMap[key[0]])
                    return true
                })
                return busGroups
            } else if (role !== "ro") {
                busGroups.push(roleMap[role])
            }
            return true
        })
        
        return busGroups
    }
    else {
        return []
    }
}


export function getBusinessGroupsRead(auth) {
    if(auth?.isAuthenticated) {
        const roles = auth.user.profile['role'].replace("[", "").replace("]", "").split(', ')
        
        let busGroups = []

        roles.map((role) => {
            if (role === "admin" || role === "ro") {
                busGroups = []
                Object.entries(roleMap).map((key, value) => {
                    busGroups.push(roleMap[key[0]])
                    return true
                })
                return busGroups
            } else {
                busGroups.push(roleMap[role])
            }
            return true
        })
        
        return busGroups
    }
    else {
        return []
    }
}